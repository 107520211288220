<template>
	<div class="hiq-events-list" :style="backgroundColor">
		<heading v-if="heading"/>
		<event-list/>
		<load-more v-if="loadMore && totalHits > count && !searching"/>
		<div v-if="loadmore && searching" class="hiq-events-list__loader--wrapper">
			<div :style="loadingStyle" class="hiq-events-list__loader"></div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import Heading from './components/Heading.vue';
import EventList from './components/EventList.vue';
import LoadMore from './components/LoadMore.vue';


export default {
	name: 'app',
	computed: {
		heading(){ return this.$store.getters.heading },
		backgroundColor() { return this.$store.getters.backgroundColor; },
		loadMore() { return this.$store.getters.showLoadMore; },
		totalHits() { return this.$store.getters.totalHits; },
		count() { return this.$store.getters.count; },
		searching() { return this.$store.state.searching },
		loadingStyle() {
            return {
                border: '16px solid ' + this.$store.state.accentColor,
                'border-top': '16px solid ' + this.$store.state.backgroundColor,
        	}
		}
	},
	components: {
		Heading,
		EventList,
		LoadMore
	}
}
</script>
