import axios from 'axios';
import queryString from 'query-string';
import { IState } from '../src/store/state';

const SiteVisionHeaders = {
    'Content-Type': 'application/json'
}

interface SearchResponse {
    message: string,
    status: number
}

const makeSearchParams = (state: any) => {

    var dataObject = {
        num: state.showAmount,
        query: state.searchInfo.query,
        count: state.searchInfo.count,
        page: state.searchInfo.page,
        type: state.searchInfo.type,
        timestamp: state.searchInfo.timestamp,
        filters: state.filters,
    };
    return dataObject
}

export const search = async (url: string, state: IState, optionalOverrides: any = ""): Promise<SearchResponse> => {
    const params = Object.assign({}, makeSearchParams(state), optionalOverrides);
    const route = `?${queryString.stringify({
        ...params,
    })}`;
    return axios.get<SearchResponse>(url + route, { headers: { ...SiteVisionHeaders } })
        .then((response: any) => response.data)
        .catch((error: any) => {
            console.error({ error });
            return null;
        })

}