<template>
    <div class="hiq-events-list__heading">
        <div :style="textColor" class="hiq-events-list__heading--container"><h2 class="hiq-events-list__heading--text">{{heading}}</h2>
            <a v-if="showAllPage" :style="accentColor" class="hiq-events-list__heading--show-all" :href="showAllPageLink">
                <span :style="textColor">Visa alla<span class="sv-visuallyhidden"> evenemang som är taggade med {{heading}}</span></span>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name:'Heading',
    computed: {
        heading() { return this.$store.getters.heading; },
        textColor() { return this.$store.getters.textColor; },
        accentColor() { return this.$store.getters.accentColor; },
        showAllPage() { return this.$store.getters.showAllPage; },
        showAllPageLink() { return this.$store.getters.showAllPageLink; }
    }
}
</script>