<template>
    <div>
        <button 
        :style="accentBackgroundColor" 
        class="hiq-button hiq-button--full-width hiq-button--plus hiq-events-list__loadMore"
        :class="isVisitSite ? 'visit' : ''"
        @click="loadMore">
        Ladda fler
        </button>
    </div>
</template>

<script>
export default {
    name:'LoadMore',
    computed: {
        isVisitSite() { return this.$store.state.isVisitSite },
        type() { return this.$store.getters.type },
        page() { return this.$store.getters.page },
        accentBackgroundColor() { return this.$store.state.isVisitSite ? this.$store.getters.accentBackgroundColor + ' color:#FFF' : this.$store.accentBackgroundColor + ' color:#000'},
    },
    methods: {
        loadMore(){
            if(this.type === 'filter'){
                this.$store.commit({
                    type: 'setSearchInfo',
                    payload: {
                        page: this.page + 1,
                        timestamp: Date.now(),
                    }
                });
                this.$store.dispatch('newSearch');
            } else {
                this.$store.commit({
                    type: 'loadMore',
                    payload: {}
                });
            }
            
        },
    }
}
</script>