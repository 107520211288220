export interface IState {
	api: string,
	events: Array<any>,
	allEvents: Array<any>,
	heading: string,
	backgroundColor: string,
	accentColor: string,
	textColor: string,
	showAmount: number,
	showLoadMore: boolean,
	useSwipe: boolean,
	filters: string,
	type: string,
	searching: boolean,
	showAllPage: string,
	landingPage: string,
	isVisitSite: boolean,
	categories: Array<string>,
	pageType: string,
	noEventsText: string,
	searchInfo: {
		type: string,
		totalHits: number,
		count: number,
		page: number,
		timestamp: number,
		query: string,
	};
}

const deafultState: IState = {
	api: '',
	events: [],
	allEvents: [],
	heading: '',
	backgroundColor: '',
	accentColor: '',
	textColor: '',
	showAmount: 0,
	showLoadMore: false,
	useSwipe: false,
	filters: '',
	type: '',
	searching: false,
	showAllPage: '',
	landingPage: '',
	isVisitSite: false,
	categories: [],
	pageType: '',
	noEventsText: '',
	searchInfo: {
		type: '',
		totalHits: 0,
		count: 0,
		page: 1,
		timestamp: 0,
		query: ''
	},
}

export default deafultState;
