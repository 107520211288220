import { IState } from "./state";
import { ActionTree, Store, ActionContext } from 'vuex';
import * as searchService from '../../searchService';
type PayloadType = 'initApp' | 'setState' | 'newSearch';
export type ActionPayload<ActionData> = {
	type: PayloadType,
	payload: ActionData
}

type ActionHandler<ActionData> = (this: Store<IState>, injectee: ActionContext<IState, IState>, payload: ActionPayload<ActionData>) => any;
type ActionCreator<ActionData> = (data: ActionData) => ActionPayload<ActionData>;


// Action creators
export const initApp: ActionCreator<IState> = (state) => ({
	type: 'initApp',
	payload: state
})

// Action handlers
const initAppAction: ActionHandler<IState> = ({ commit }, { payload }) => {
	commit({
		type: 'setState',
		payload
	})
}

export const newSearch: ActionCreator<IState> = (state) => ({
	type: "newSearch",
	payload: state
})

const newSearchAction: ActionHandler<IState> = ({ commit, state }) => {
	commit({
		type: 'setSearching',
		payload: true
	});
	searchService.search(state.api + '/events', state)
		.then((result: any) => {
			if(result.searchInfo.timestamp === state.searchInfo.timestamp){
				commit({
					type: 'setSearchInfo',
					payload: result.searchInfo,
				})
				commit({
					type: 'setSearchHits',
					payload: result.hits
				});
				commit({
					type: 'setSearching',
					payload: false
				});
			}
		})
		.catch(console.error);
}

const actions: ActionTree<IState, IState> = {
	initApp: initAppAction,
	newSearch: newSearchAction
}

export default actions;
