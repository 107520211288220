import { MutationTree, Mutation } from 'vuex';
import { IState } from './state';
import { ActionPayload } from './actions';

type MutationHandler<PayloadType> = (state: IState, payload: ActionPayload<PayloadType>) => any;

const setStateMutation: MutationHandler<IState> = (state, { payload }) => {
	Object.assign(state, payload);
}
const setSearchInfo: MutationHandler<IState> = (state, { payload }) => {
	state.searchInfo = Object.assign({}, state.searchInfo, payload);
}

const loadMore: MutationHandler<IState> = (state, { payload }) => {
	var currentIndex = state.events.length;
	var newIndex = currentIndex + state.showAmount;
	var toAppend = state.allEvents.slice(currentIndex, newIndex);
	state.events = state.events.concat(toAppend);
	state.searchInfo.count = state.events.length;
}

const setSearching: MutationHandler<any> = (state, { payload }) => {
	state.searching = payload;
}


const setSearchHits: MutationHandler<any> = (state, { payload }) => {
	state.events = payload;
}

const mutations: MutationTree<IState> = {
	setState: setStateMutation,
	loadMore: loadMore,
	setSearchInfo: setSearchInfo,
	setSearching: setSearching,
	setSearchHits: setSearchHits,
}

export default mutations
